import React from "react";

import BBWhite from "../assets/BB-WhiteLogo.svg";
import twitter from "../assets/twitter.svg";
import discord from "../assets/discord.svg";
import medium from "../assets/medium.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="box">
        <div className="footer-content">
          <div className="footer-content-left">
            <img src={BBWhite} />
            {/* <div className="links">
              <a href="#">FAQ</a>
              <a href="#">Privacy Policy</a>
              <a href="#">Security</a>
            </div> */}
          </div>
          <div className="socials">
            <Link to="https://twitter.com/BoogaBeras">
              <img src={twitter} alt="" />
            </Link>
            <Link to="https://discord.gg/boogaberas">
              <img src={discord} alt="" />
            </Link>
            <Link to="https://medium.com/booga-beras">
              <img src={medium} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
