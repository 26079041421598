"usecontext";
import React from "react";

import card1Img from "../assets/card1Img.png";
import honey from "../assets/honey.png";
import Scythe from "../assets/Scythe.png";
import BerachainValidators from "../assets/BerachainValidators.png";
import CosmosValidators from "../assets/CosmosValidators.png";
import Cosmos from "../assets/Cosmos.png";
import Lair from "../assets/Lair.png";
import Berachain from "../assets/Berachain.png";
import Osmosis from "../assets/Osmosis.png";
import cosmosItemCard from "../assets/cosmosItemCard.png";
import Celestia from "../assets/Celestia.png";
import DYDX from "../assets/DYDX.png";
import Dymension from "../assets/Dymension.png";
import Eigenlayer from "../assets/Eigenlayer.png";
import chefbear from "../assets/chefbear.png";
import bear2 from "../assets/bear2.png";
import bird from "../assets/bird.png";
import honeyCard from "../assets/honeyCard.png";
import cosmosCardImg from "../assets/cosmosImg.png";
import HoneypotDots from "../assets/honeypotdots.svg";
import LSTDots from "../assets/lst-dots.svg";
import WardenDots1 from "../assets/warden-left-dots.svg";
import WardenDots2 from "../assets/warden-right-dots.svg";
import BearCircle from "../assets/bear-circle.svg";
import ValidatorDot1 from "../assets/validator-dot1.svg";
import ValidatorDot2 from "../assets/validator-dot2.svg";
import ValidatorDot3 from "../assets/validator-dot3.svg";

import BGImg from "../assets/booga-bera-bg.png";
import Logo from "../assets/BB-Logo.svg";
import BBWhite from "../assets/BB-WhiteLogo.svg";
import Bear from "../assets/Dune-Bera.png";
import { BsMedium, BsTwitter, BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";

// import gsap from "gsap";
// import {
//   Back,
//   Power3,
//   Power1,
//   Power2,
//   Power4,
//   Linear,
//   Expo,
//   Circ,
// } from "gsap/dist/gsap";
// import { Draggable } from "gsap/Draggable";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";

const Home2 = () => {
  return (
    <div className="boogabera">
      <main className="boogabera-content">
        <img src={BGImg} alt="" />
        <Link to="/">
          <img src={Logo} alt="" className="logo-img" />
        </Link>
        <button className="top-button">Coming Thoon</button>
        <section className="boogabera-center-cont">
          <img src={BBWhite} alt="" className="heading-img" />
          <h3>
            Hold your JPEG and get rewards <br /> Simple
          </h3>
          <div className="buttons-div">
            <Link to="https://opensea.io/collection/booga-beras-5">
              <button className="mint-btn">JOIN</button>
            </Link>
            <button className="enter-btn">ENTER THOON</button>
          </div>
          <div className="social-icons">
            <Link to="https://twitter.com/BoogaBeras">
              <BsTwitter />
            </Link>
            <Link to="https://discord.gg/boogaberas">
              <BsDiscord />
            </Link>
            <Link to="https://medium.com/booga-beras">
              <BsMedium />
            </Link>
          </div>
        </section>
        <img src={Bear} alt="" className="bear-img" />
        <div className="linear-gradient"></div>
        <section className="boogabera-card-section">
          <div className="box">
            <div className="boogabera-card-container">
              <div className="boogabera-card-row1">
                <div className="boogabera-card-row1-col1">
                  <h2>Anatomy of Booga Beras</h2>
                  <div className="glow"></div>
                  <div className="circle-yellow">
                    <img src={card1Img} alt="" />
                  </div>
                </div>
                <div className="boogabera-card-row1-col2">
                  <img src={honey} alt="" />
                  <div>
                    <h2>A Community War Chest</h2>
                    <p>Yeet into Bera Protocols</p>
                  </div>
                </div>
                <div className="boogabera-card-row1-col3">
                  <img src={Scythe} alt="" />
                  <div>
                    <h2>Soulbound Governance</h2>
                    <p>OGs Build Influence</p>
                  </div>
                </div>
              </div>
              <div className="boogabera-card-row2">
                <div className="boogabera-card-row1-col3">
                  <img src={BerachainValidators} alt="" />
                  <div>
                    <h2>Berachain Validator</h2>
                    <p>Emissions Influence</p>
                  </div>
                </div>
                <div className="boogabera-card-row1-col3">
                  <img src={Cosmos} alt="" />
                  <div>
                    <h2>Booga Bera Issued Cosmos LSTs</h2>
                    <p>Assets Primed for Defi</p>
                  </div>
                </div>
                <div className="boogabera-card-row1-col3">
                  <img src={Lair} alt="" />
                  <div>
                    <h2>Dynamic Utility NFT Standard</h2>
                    <p>Tailored Yield Strategies</p>
                  </div>
                </div>
                <div className="boogabera-card-row1-col3">
                  <img src={CosmosValidators} alt="" />
                  <div>
                    <h2>Cosmos Validators</h2>
                    <p>Diversified Yield</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="validators-section">
          <img src={ValidatorDot1} alt="" className="vd-left" />
          <img src={ValidatorDot2} alt="" className="vd-right" />
          <img src={ValidatorDot3} alt="" className="vd-bottom" />
          <div className="box">
            <div className="validators-container">
              <div className="validators-text">
                <h2>Our Validators</h2>
                <p>
                  As a Booga Beras or Honeycomb holder, you earn a portion of
                  rewards generated by our validators! Stake with us and help
                  the BB ecosystem grow!
                </p>
              </div>
              <div className="validator-card">
                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={Eigenlayer} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>Eigenlayer</h3>
                      <p>
                        APR: ~3.4% <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="https://app.eigenlayer.xyz/operator/0xde7a0cbbb869376e062c473be57ce43ead4060ff">
                      {" "}
                      <button className="btn1">Stake</button>
                    </Link>
                  </div>
                </div>
                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={Osmosis} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>Osmosis</h3>
                      <p>
                        APR: 12% <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="https://www.mintscan.io/osmosis/validators/osmovaloper1tf7wjthwt9mgywqvjdkj974rp5awzzm43ddhlp">
                      {" "}
                      <button className="btn1">Stake</button>
                    </Link>
                    <Link to="https://vased.fi/">
                      <button className="btn2">LIQUID STAKE</button>
                    </Link>
                  </div>
                </div>
                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={cosmosItemCard} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>Cosmos</h3>
                      <p>
                        APR: 16.2% <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="">
                      <button className="btn2">COMING THOON</button>
                    </Link>
                  </div>
                </div>
                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={Celestia} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>Celestia</h3>
                      <p>
                        APR: 10.8% <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="">
                      <button className="btn2">COMING THOON</button>
                    </Link>
                  </div>
                </div>
                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={DYDX} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>DYDX</h3>
                      <p>
                        APR: ~15%
                        <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="">
                      <button className="btn2">COMING THOON</button>
                    </Link>
                  </div>
                </div>
                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={Dymension} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>Dymension</h3>
                      <p>
                        APR: 9.56% <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="">
                      <button className="btn2">COMING THOON</button>
                    </Link>
                  </div>
                </div>

                <div className="validator-card-item">
                  <div className="validator-card-item-top">
                    <img src={Berachain} alt="" />
                    <div className="validator-card-item-top-left">
                      <h3>Berachain</h3>
                      <p>
                        APR: -% <br /> BB Rewards: 20% <br /> HC Rewards: 5%
                      </p>
                    </div>
                  </div>
                  <div className="validator-card-item-bottom">
                    <Link to="">
                      <button className="btn2">COMING THOON</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="featured-section">
          <div className="box">
            <div className="featured-container">
              <div className="featured-container-item">
                <div className="featured-container-item-left-card">
                  <img src={HoneypotDots} alt="" className="honeypot-dots" />
                  <h2>The HoneyPot</h2>
                  <p>
                    The Booga Beras treasury! Curated deployments into major
                    Berachain protocols, generating rewards for our holders. Your best
                    form of exposure to the Berachain ecosystem as a BB holder.
                  </p>
                  <Link to="https://docs.boogaberas.com/value-streams-utility/the-honeypot-treasury">
                    <button className="learn-more-btn">LEARN MORE</button>
                  </Link>
                </div>
                <div className="featured-container-item-right-card">
                  <div className="foreground">
                    <img src={honeyCard} alt="Clear Honey" />
                  </div>
                </div>
              </div>

              <div className="featured-container-item featured-container-item-reverse">
                <div className="featured-container-item-left-card">
                  <img src={LSTDots} alt="" className="honeypot-dots" />
                  <h2>Booga Beras LSTs</h2>
                  <p>
                    Through Booga Beras, you’ll be able to test the first
                    iteration of Vase Finance LSTs! As the first users of Vase
                    Finance, you will also receive [REDACTED] on Vase Finance’s
                    full launch.
                  </p>
                  <Link to="https://docs.boogaberas.com/value-streams-utility/liquid-staked-tokens-vase-finance-powered">
                    <button className="learn-more-btn">LEARN MORE</button>
                  </Link>
                </div>
                <div className="featured-container-item-right-card">
                  <div className="foreground">
                    <img src={cosmosCardImg} alt="Clear Honey" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="lair-section">
          <div className="box">
            <div className="lair-section-container">
              <h2>The Lair</h2>
              <p>
                In here, you’ll be able to customize the traits of your NFT. You
                can change both utility and looks! Make your Bera your own, and
                tailor which yield strategy you would like to have! Every Bera
                is unique.
              </p>
              <Link to="https://docs.boogaberas.com/value-streams-utility/the-lair-re-roll-traits">
                <button className="learn-more-btn">LEARN MORE</button>
              </Link>
            </div>
          </div>
          <div className="lair-section-bears">
            <div className="black-linear"></div>
            <div className="bear-img bi-img">
              <img src={bear2} alt="" />
              <div className="dotted-line">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="550"
                  height="2"
                  viewBox="0 0 550 2"
                  fill="none"
                >
                  <path
                    opacity="0.4"
                    d="M0 1H550"
                    stroke="white"
                    stroke-width="2"
                    stroke-dasharray="6 6"
                  />
                </svg>
              </div>
              <button>20% Validator Rewards</button>
            </div>
            <div className="bear-img">
              <img src={chefbear} alt="" />
              <button>25% Validator Rewards</button>
            </div>
          </div>
        </section>

        <section className="the-warden-section">
          <div className="box">
            <div className="the-warden-container">
              <img src={WardenDots1} alt="" className="warden-left-dots" />
              <img src={WardenDots2} alt="" className="warden-right-dots" />
              <h2>The Warden</h2>
              <p>
                The Booga Beras governance contract. Stake your BB to earn
                $BOOGA!
              </p>
              <div className="the-warden-container-cards">
                <div className="the-warden-container-card-item">
                  <div className="circle-glow"></div>
                  <div className="circle">
                    <img src={bird} alt="" />
                  </div>
                </div>
                <div className="the-warden-container-card-item left-card">
                  <h3>$BOOGA Governance</h3>
                  <p>
                    Our custom Soulbound governance contract.
                    Designed to reward OG holders and ready to be
                    adopted by any NFT collection. As more adopt it,
                    BB holder rewards grow!
                  </p>
                  <Link to="https://docs.boogaberas.com/value-streams-utility/the-warden-governance">
                    <button className="learn-more-btn">LEARN MORE</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
};

export default Home2;
